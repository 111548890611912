<template>
  <div class="personal-container">
    <van-form ref="form">
      <van-cell-group>
        <van-field
          v-model="username"
          name="姓名"
          label="姓名"
          placeholder="请填写姓名"
          readonly
        >
          <template #right-icon>
            <van-icon name="edit" v-if="relation=='1'" @click="infoEdit=true"/>
          </template>
        </van-field>
        <van-field
          v-model="phone"
          type="tel"
          name="联系电话"
          label="联系电话"
          placeholder="请填写联系电话"
          readonly
        >
          <template #right-icon>
            <van-icon name="edit" v-if="relation=='1'" @click="infoEdit=true"/>
          </template>
        </van-field>
        <van-field
          v-model="hospital"
          readonly
          name="院区"
          label="院区"
          placeholder="请选择医院"
        />
          <!-- is-link -->
          <!-- @click="showPicker = true" -->
        <!-- <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup> -->
        <van-field
          v-model="bedNumAll"
          readonly
          name="床位号"
          label="床位号"
          placeholder="请选择床位号"
        />
          <!-- is-link -->
          <!-- @click="showBedPicker = true"
        <van-popup v-model="showBedPicker" position="bottom">
          <van-cascader
            v-model="cascaderValue"
            title="请选择床位号"
            :options="options"
            @close="show = false"
            @finish="onFinish"
          />
        </van-popup> -->
        <van-field
          v-model="nutritionalAnalysis"
          is-link
          readonly
          name="营养分析"
          label="营养分析"
          placeholder=""
          @click="goNutritionalAnalysis"
        >
        </van-field>
      </van-cell-group>
    </van-form>

    <!-- 底部导航按钮 -->
    <div class="page-bottom-nav">
      <van-tabbar
        v-model="activeNav"
        active-color="#000000"
        inactive-color="#767676"
      >
        <van-tabbar-item icon="home-o" @click="goToHome">
          <span>首页</span>
          <template #icon="props">
            <img
              :src="
                props.active
                  ? require('@/assets/mHomeActive.png')
                  : require('@/assets/mHome.png')
              "
            />
          </template>
        </van-tabbar-item>
        <van-tabbar-item icon="search" @click="goToOrder">
          <span>订单</span>
          <template #icon="props">
            <img
              :src="
                props.active
                  ? require('@/assets/mOrderActive.png')
                  : require('@/assets/mOrder.png')
              "
            />
          </template>
        </van-tabbar-item>
        <van-tabbar-item icon="friends-o">
          <span>个人中心</span>
          <template #icon="props">
            <img
              :src="
                props.active
                  ? require('@/assets/mPersonalActive.png')
                  : require('@/assets/mPersonal.png')
              "
            />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>

    <van-popup v-model="infoEdit" class="infoEdit">
        <div>
          <div style="margin-bottom:18px;margin-top:14px;font-weight: 600; padding: 0.6rem 3rem 0;">修改信息</div>
          <div class="contentPro" style="padding: 0.6rem 3rem;">
              <van-form validate-first  @submit="onSubmit">
                <div style="height:9rem;">
                  <van-field
                    v-model="newUsername"
                    name="姓名"
                    label="姓名"
                    placeholder="请填写姓名"
                    label-align='left'
                    :rules="[
                      { required: true, message: '姓名不能为空' }
                    ]"
                    style="height: 4rem;"
                  >
                  </van-field>
                  <van-field
                    v-model="newPhone"
                    type="tel"
                    name="联系电话"
                    label="联系电话"
                    placeholder="请填写联系电话"
                    :rules="[
                      { required: true, message: '联系电话不能为空' },
                      { message:'联系电话格式错误', pattern:new RegExp(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/)}
                    ]"
                    style="height: 4rem;"
                  >
                  </van-field>
                </div>
                <div class="page-bottom-btn">
                  <van-button type="primary" size="large" native-type="submit" style="width: 100%;">确认</van-button>
                </div>
              </van-form>
          </div>
        </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "personalCenter",
  data() {
    return {
      activeNav: 2,
      username: "",
      phone: "",
      hospital: "",
      bedNumAll: "",
      nutritionalAnalysis: "",
      relation: '',
      infoEdit: false,
      showPicker: false,
      // columns: [],
      showBedPicker: false,
      cascaderValue: '',
      // 选项列表，children 代表子选项，支持多级嵌套
      // options: [],
      newUsername: '',
      newPhone: '',
      roomInfo: {},
      loginInfo: {},
    };
  },
  watch: {
    
  },
  created() {
    this.relation = JSON.parse(localStorage.getItem('relation'));
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if(userInfo.userName||userInfo.userPhone){
      this.username=userInfo.userName;
      this.phone=userInfo.userPhone;
    }
    this.roomInfo = JSON.parse(localStorage.getItem('roomInfo'));
    this.hospital = this.roomInfo.hospitalName;
    this.bedNumAll = this.roomInfo.bunkNo;
    this.loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    this.initData();
  },
  methods: {
    initData(){
      this.$http.postAction("/queryUserById", {
        openid: this.loginInfo.openid,
      }).then((res) => {
        if (res.code == 200) {
          this.username = res.data.xingMing;
          this.phone = res.data.dianHua;
          // this.hospital = res.data.yuanQuId;
          // this.bedNumAll = res.data.dangQianCw;
        }
      });
    },
    onSubmit(){
      //接口 修改信息
      this.$http.postAction("/center/userMsgOption", {
        openid: this.loginInfo.openid,
        userName: this.newUsername,
        userPhone: this.newPhone,
        hospitalId: this.roomInfo.hospitalId,
        bunkNo: this.roomInfo.bunkNo,
      }).then((res) => {
        if (res.code == 200) {
          this.$toast("修改成功");
          this.infoEdit = false;
          this.initData();
          const userInfo = {
            userName: this.newUsername,
            userPhone: this.newPhone,
          }
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
        }
      });
    },
    // onConfirm( selectedOptions ){
    //   console.log("aaa selectedOptions:",selectedOptions)
    //   this.hospital = selectedOptions.text;
    //   this.showPicker = false;
    // },
    // onFinish({ selectedOptions }) {
    //   console.log("aaa selectedOptions:",selectedOptions)
    //   this.showBedPicker = false;
    //   this.bedNumAll = selectedOptions.map((option) => option.text).join('/');
    // },
    goNutritionalAnalysis() {
      this.$router.push({ path: "/nutritionalAnalysis" });
    },
    // 点击跳转首页
    goToHome() {
      this.$router.replace({ path: "/home" });
    },
    // 点击跳转订单页
    goToOrder() {
      this.$router.replace({ path: "/myOrder" });
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .van-field__label{
  span{
    display: block 
  }
}
.infoEdit{
  /deep/ .van-field--error .van-field__control::placeholder {
    color: #969799  !important;
    font-weight: normal;
    font-size: 14px;
    -webkit-text-fill-color: currentColor;
  }
  /deep/.van-field__control {
    font-size: 14px;
    color: #969799 !important;
    font-weight: normal;
  }
}

</style>